
import { useRoute } from "vue-router";
import { defineComponent } from "vue";
import qs from "qs";
export default defineComponent({
  name: "Pay",
  setup() {
    const route = useRoute();
    // eslint-disable-next-line no-undef
    wx.miniProgram.redirectTo({
      url: `/pages/pay/main?${qs.stringify(route.query)}`
    });
    return {};
  }
});
